.intro {
	height: 100vh;
	position: relative;
	background-color: map-get($colors, intro-bg);
	padding-bottom: 0px;
}
.container--intro {
	position: relative;
	.title {
		position: absolute;
		h5 {
			text-align: right;
		}
	}
	.header {
		position: absolute;
		width: 100%;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		.content {
			width: 100%;
			text-align: center;
			@each $item, $break in $breakpoints-margins {
				@include mappy-bp($break) {
					margin-bottom: $break + $break + $break + 100px;
				}
			}
		}
	}
	.contact {
		display: flex;
		width: 100% !important;
		height: 100vh;
		position: relative;

	}
	.cards {
		@include grid-container;
		align-self: flex-end;
		@include grid-column(3, $card-contact-grid);
		@include grid-media($card-contact-grid-xlarge){
			@include grid-column(3, $card-contact-grid-xlarge);
		}
		@include grid-media($card-contact-grid-xxlarge){
			@include grid-column(3, $card-contact-grid-xxlarge);
		}
		@include grid-media($card-contact-grid-xxxlarge){
			@include grid-column(3, $card-contact-grid-xxxlarge);
		}

	}
	.card {
		background-color: map-get($colors, intro-card-bg);
		position: relative;
		@include grid-column(1, $card-contact-grid);

		@include grid-media($card-contact-grid-xlarge){
			@include grid-column(1, $card-contact-grid-xlarge);
		}
		@include grid-media($card-contact-grid-xxlarge){
			@include grid-column(1, $card-contact-grid-xxlarge);
		}
		@include grid-media($card-contact-grid-xxxlarge){
			@include grid-column(1, $card-contact-grid-xxxlarge);
		}

		@each $item, $break in $breakpoints-margins {
			@include mappy-bp($break) {
				margin-bottom: $break/3;
			}
		}
		.content {
			width: 100%;
			@each $item, $break in $breakpoints-margins {
				@include mappy-bp($break) {
					margin-left: $break/2;
					padding-top: $break/2;
					padding-bottom: $break/2;
				}
			}
		}
		.image {
			display:block;
			float:left;
			@each $item, $break in $breakpoints-margins {
				@include mappy-bp($break) {
					padding-right: $break;
				}
			}
			img {
				width: 100px;
				max-width: 100px;
			}
		}
		.text {
			float:left;
		}
	}
}