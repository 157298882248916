@charset "utf-8";

//@import 'normalize';
@import 'reset';
@import '_settings';
@import '_mixins';

@import '../../bower_components/mappy-breakpoints/mappy-breakpoints';
//@import '../../bower_components/breakpoint-sass/stylesheets/breakpoint';
//@import '../../bower_components/susy/sass/susy';
@import '../../bower_components/typi/scss/typi';

// extra custom components
@import '../../bourbon/bourbon';
@import '_neat_grid_setting';
@import '../../neat/neat';

@import 'classes_usefull';

// https://github.com/drublic/Sass-Mixins
@import 'components/Sass-Mixins-master/mixins';

//@import 'components/hamburgers/_sass/hamburgers/hamburgers';
@import 'components/transitions/transitions';
//@import 'components/layzr/layzr';

// http://fontawesome.io/get-started/
// http://fontawesome.io/icons/
@import 'components/font-awesome-4.7.0/scss/font-awesome';

html {
	@include scrollbars(1em, map-get($colors, scroll-front), map-get($colors, scroll-back));
}
html, body {
	width: 100%;
	height: 100%;
}

html, body, * {
	margin: 0;
	padding: 0;
	@include typi-base();
	font-family: $font-body-family;
	font-weight: $font-body-weight;
	color: map-get($colors, primary);
}
i {
	vertical-align: middle;
}

p, span {
	&.micro {
		font-size: 0.8em;
	}
	a {
		text-decoration: underline;
		color: map-get($colors, primary);
	}
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
	font-family: $font-head-family;
	font-weight: $font-head-weight;
}
h1, .h1 {
	@include typi($h1-sizes);
	&.hero {
		@include typi($hero-sizes);
		font-weight: 100;
	}
}
h2, .h2 {
	@include typi($h2-sizes);
}
h3, .h3 {
	@include typi($h3-sizes);
}
h4, .h4 {
	@include typi($h4-sizes);
}
h5, .h5 {
	@include typi($h5-sizes);
}

a {
	color: map-get($colors, primary);
	text-decoration: none;
}
i {
	vertical-align: middle;
}

#smoothstate {
	width: 100%;
	height: 100%;
}
.wrapper {
	//@include grid-container;
	width:100%;
	padding: 0px;

	margin-left: 220px;
	@each $item, $break in $breakpoints-navigation-width {
		@include mappy-bp($item) {
			margin-left: $break;
		}
	}
	width: auto;
	float:left;
	position: relative;
}
.logo {
	img {
		margin-right:20px;
	}
	//background-color: #f1f1f1;
	margin-bottom: 20px;
/*
	&:after {
		content: '';
		display: block;
		padding-bottom: calc(100%);
	}
*/
}

.loader {
	position: fixed;
	top: 0px;
	left:0px;
	height: 100%;
	width: 100%;
	@extend %trans-all-slow;
	opacity: 0;
	pointer-events: none;
	color:#ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 99900;

	&.active {
		background: map-get($colors, primary);
				opacity: 1;
	}
	i {
		max-width: 50%;
		color:#ffffff;
	}
}
.navigation {
	position: fixed;
	top: 0px;
	left:0px;
	float:left;
	background-color: map-get($colors, navigation-bg);

	height: 100%;
	//width: 200px;
	@each $item, $break in $breakpoints-navigation-width {
		@include mappy-bp($item) {
			width: $break - 20px;
		}
	}


	padding: 20px 0px 20px 20px;
	h5 {

	}
	ul {
		@include mappy-bp( max-width large) {
			display: none;
		}
		li {
			//border-top: 1px solid #ffffff;
			@include typi($nav-sizes);


			padding-top: 10px;
			padding-bottom: 10px;
			text-transform: uppercase;
			.fa {
				vertical-align: middle;
				margin-top: -2px;
				padding-right: 0px;
				min-width: 20px;
				color: inherit;
			}
			h5 {
				border-top: 1px solid map-get($colors, navigation-head);
				padding-top: 5px;
				color: map-get($colors, navigation-head);
				font-weight: $font-nav-head-weight;
			}
			a {
				@extend %trans-all;
				color:  map-get($colors, navigation-txt);
				font-weight: $font-nav-weight;
				&:hover {
					color: map-get($colors, navigation-txt-hover);
				}

			}
			&.active {
				a {
					color: map-get($colors, navigation-txt-active);
				}
				//border-top: 1px solid #000000;
			}

		}
	}
}

// here we define the global grids (based on 12 columns) for the objects
// these are divided by content + elements
.container {
	@include grid-container;
	padding-bottom: 200px;
	.images, .slideshow {
		z-index: -1;
		@include grid-column(12, $container-grid);
		@include grid-media($container-grid-xlarge){
			@include grid-column(12, $container-grid-xlarge);
		}
		@include grid-media($container-grid-xxlarge){
			@include grid-column(12, $container-grid-xxlarge);
		}
		@include grid-media($container-grid-xxxlarge){
			@include grid-column(12, $container-grid-xxxlarge);
		}
		img {
			width: 100%;
		}

	}
	.title {
		z-index: 999;
		color: map-get($colors, black);

		@include grid-column(12, $container-grid);
		@include grid-media($container-grid-xlarge){
			@include grid-column(12, $container-grid-xlarge);
		}
		@include grid-media($container-grid-xxlarge){
			@include grid-column(12, $container-grid-xxlarge);
		}
		@include grid-media($container-grid-xxxlarge){
			@include grid-column(12, $container-grid-xxxlarge);
		}

		h1, h2, h3, h4, h5,
		.h1, .h2, .h3, .h4, .h5 {

			@each $item, $break in $breakpoints-layout-title-margins-top {
				@include mappy-bp($break) {
					margin-top: $break;
				}
			}
			@each $item, $break in $breakpoints-layout-title-paddings-top {
				@include mappy-bp($break) {
					padding-top: $break;
				}
			}

			text-transform: uppercase;
			border-top: 1px solid map-get($colors, black);
			color: map-get($colors, black);
		}
		&.gray-dark {
			color: map-get($colors, gray-dark);
			h1, h2, h3, h4, h5,
			.h1, .h2, .h3, .h4, .h5 {
				border-top: 1px solid map-get($colors, gray-dark);
				color: map-get($colors, gray-dark);
			}
		}
		&.gray-medium {
			color: map-get($colors, gray-medium);
			h1, h2, h3, h4, h5,
			.h1, .h2, .h3, .h4, .h5 {
				border-top: 1px solid map-get($colors, gray-medium);
				color: map-get($colors, gray-medium);
			}
		}
		&.gray-light {
			color: map-get($colors, gray-light);
			h1, h2, h3, h4, h5,
			.h1, .h2, .h3, .h4, .h5 {
				border-top: 1px solid map-get($colors, gray-light);
				color: map-get($colors, gray-light);
			}
		}
	}
	.content {
		padding-top: 40px;

		@include grid-column(12, $container-grid);

		@include grid-media($container-grid-large){
			@include grid-column(12, $container-grid-large);
		}
		@include grid-media($container-grid-xlarge){
			@include grid-column(4, $container-grid-xlarge);
		}
		@include grid-media($container-grid-xxlarge){
			@include grid-column(3, $container-grid-xxlarge);
		}
		@include grid-media($container-grid-xxxlarge){
			@include grid-column(3, $container-grid-xxxlarge);
		}
		&.half {
			@include grid-media($container-grid-large){
				@include grid-column(12, $container-grid-large);
			}
			@include grid-media($container-grid-xlarge){
				@include grid-column(6, $container-grid-xlarge);
			}
			@include grid-media($container-grid-xxlarge){
				@include grid-column(6, $container-grid-xxlarge);
			}
			@include grid-media($container-grid-xxxlarge){
				@include grid-column(6, $container-grid-xxxlarge);
			}
		}
		&.wide {
			@include grid-media($container-grid-large){
				@include grid-column(12, $container-grid-large);
			}
			@include grid-media($container-grid-xlarge){
				@include grid-column(6, $container-grid-xlarge);
			}
			@include grid-media($container-grid-xxlarge){
				@include grid-column(5, $container-grid-xxlarge);
			}
			@include grid-media($container-grid-xxxlarge){
				@include grid-column(5, $container-grid-xxxlarge);
			}
		}
		&.wider {
			@include grid-media($container-grid-large){
				@include grid-column(12, $container-grid-large);
			}
			@include grid-media($container-grid-xlarge){
				@include grid-column(8, $container-grid-xlarge);
			}
			@include grid-media($container-grid-xxlarge){
				@include grid-column(7, $container-grid-xxlarge);
			}
			@include grid-media($container-grid-xxxlarge){
				@include grid-column(7, $container-grid-xxxlarge);
			}
		}
		&.full {
			@include grid-media($container-grid-large){
				@include grid-column(12, $container-grid-large);
			}
			@include grid-media($container-grid-xlarge){
				@include grid-column(12, $container-grid-xlarge);
			}
			@include grid-media($container-grid-xxlarge){
				@include grid-column(12, $container-grid-xxlarge);
			}
			@include grid-media($container-grid-xxxlarge){
				@include grid-column(12, $container-grid-xxxlarge);
			}
		}

		p {
			display: block;
		}
	}
	.elements {
		z-index: 9;
		position: relative;
		.container--collapse {
			//@include grid-container;
			@include grid-collapse;
		}

		@include grid-column(12, $container-grid);
		@include grid-media($container-grid-large){
			@include grid-column(12, $container-grid-large);
		}
		@include grid-media($container-grid-xlarge){
			@include grid-column(8, $container-grid-xlarge);
		}
		@include grid-media($container-grid-xxlarge){
			@include grid-column(9, $container-grid-xxlarge);
		}
		@include grid-media($container-grid-xxxlarge){
			@include grid-column(9, $container-grid-xxxlarge);
		}
		&.wide {
			@include grid-media($container-grid-large){
				@include grid-column(12, $container-grid-large);
			}
			@include grid-media($container-grid-xlarge){
				@include grid-column(6, $container-grid-xlarge);
			}
			@include grid-media($container-grid-xxlarge){
				@include grid-column(7, $container-grid-xxlarge);
			}
			@include grid-media($container-grid-xxxlarge){
				@include grid-column(7, $container-grid-xxxlarge);
			}
		}
		&.wider {
			@include grid-media($container-grid-large){
				@include grid-column(12, $container-grid-large);
			}
			@include grid-media($container-grid-xlarge){
				@include grid-column(4, $container-grid-xlarge);
			}
			@include grid-media($container-grid-xxlarge){
				@include grid-column(5, $container-grid-xxlarge);
			}
			@include grid-media($container-grid-xxxlarge){
				@include grid-column(5, $container-grid-xxxlarge);
			}
		}


		.title {
/*
			@include grid-column(4, $card-color-grid);
			@include grid-media($card-color-grid-xlarge){
				@include grid-column(3, $card-color-grid-xlarge);
			}
			@include grid-media($card-color-grid-xxlarge){
				@include grid-column(4, $card-color-grid-xxlarge);
			}
			@include grid-media($card-color-grid-xxxlarge){
				@include grid-column(6, $card-color-grid-xxxlarge);
			}
*/

			h1, h2, h3, h4, h5 {
				padding-bottom: 0px;

				@each $item, $break in $breakpoints-layout-title-margins-top {
					@include mappy-bp($break) {
						padding-top: $break;
					}
				}
				text-transform: uppercase;
				border-top: 0px solid map-get($colors, gray-dark);
			}
			p {
/*
				@include grid-column(4, $card-color-grid);
				@include grid-media($card-color-grid-xlarge){
					@include grid-column(3, $card-color-grid-xlarge);
				}
				@include grid-media($card-color-grid-xxlarge){
					@include grid-column(4, $card-color-grid-xxlarge);
				}
				@include grid-media($card-color-grid-xxxlarge){
					@include grid-column(6, $card-color-grid-xxxlarge);
				}
*/
				padding-bottom: 10px;
				padding-top: 5px;
			}
		}

	}
	.downloads, .downloads-overview {
		//border: 1px solid map-get($colors, gray-light);
		border-radius: 10px;
		background-color: map-get($colors, download-bg);
		padding: 20px;
		width: calc(100% - 40px);
		//margin-top: 20px;
		&--title {
			@extend h5;
			padding-bottom: 0px !important;
			margin-bottom: 0px !important;
			text-transform: uppercase;
			margin-bottom: 10px;
		}
		a {
			width:100%;
			color: map-get($colors, text-overall);
		}
		span {
			display: table;
		}
		ul {
			width:100%;
			li {
				width: 100%;
				padding-top: 10px;
				display: table;
				margin-left: 0 !important;
			}
		}
	}
	.downloads-overview {
		background-color: transparent;
		padding: 0px;
		width: 100%;
		span {
			@extend h5;
		}
		ul {
			clear: both;
			li {
				@include grid-media($container-grid-large){
					width: calc(49% - 30px);
					margin-right: 30px;
				}
				@include grid-media($container-grid-xxxlarge){
					width: calc(30% - 20px);
					margin-right: 20px;
				}

				float:left;
				padding-top: 5px;
				padding-bottom: 5px;

			}
		}
	}
}


@import '__slideshow';
@import '__container--colors';
@import '__container--images';
@import '__container--intro';