.container--colors {

	.cards {}
	.card {
		@include grid-column(1, $card-color-grid);
		@include grid-media($card-color-grid-xlarge){
			@include grid-column(1, $card-color-grid-xlarge);
		}
		@include grid-media($card-color-grid-xxlarge){
			@include grid-column(1, $card-color-grid-xxlarge);
		}
		@include grid-media($card-color-grid-xxxlarge){
			@include grid-column(1, $card-color-grid-xxxlarge);
		}
	}
	.card--color {
		min-height: 200px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
		&:hover .card--color--copy {
			@include opacity(1);
		}

		//width:150px;
		padding: 5px 5px 15px 5px;
		margin-top: 15px;
		border: 1px solid map-get($colors, gray-light);
		&--example {
			width: 100%;
			position:relative;
			color: #fff;
			&.light {
				color:#000;
			}
			&:after {
				content: '';
				display: block;
				padding-bottom: calc(66%);
			}
			&.triangle {
				position: relative;
				width: 100%;
				overflow: hidden;

				&:after {
					content: '';
					display: block;
					padding-bottom: calc(66%);
				}

			}
			.card--color--copy {
				@extend %trans-all;
				width: 100%;
				height: 100%;
				position: absolute;
				color: inherit;
				@include opacity(0);
				&:before {
					color: inherit;
					content: '\f0ea';
					font-family: FontAwesome;
					font-size: 20px;
					left: calc(50% - 10px);
					position:absolute;
					top: calc(50% - 5px);
				}
			}
		}
		&--hex, &--rgb, &--cmyk {
			margin: 10px 10px 0 10px;
		}
		&--hex {
			text-align: center;
		}
		&--rgb, &--cmyk {
			width: calc(40% - 20px);
			float:left;
			padding-bottom: 0px;
			display: block;
			>div {
				width: 100%;
				span {
					&:first-child {
						float: left;
						opacity: 0.5;
					}
					&:last-child {
						float: right;
					}
				}
				clear:both;
			}
			&::after {
				clear:both;
			}
		}
		&--cmyk {
			float: right;
		}
	}
}