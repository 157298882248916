.slideshow {
	@include grid-container;
	@include grid-collapse;
	.slideshow-wrapper {
		z-index: 9;
		&.images-more {
			.cycle-slideshow {
				border: 1px map-get($colors, slideshow-bg) solid;
			}
		}

		.controllers {
			text-align: right;
			display:table;
			>div {
				background-color: map-get($colors, slideshow-bg);
				display:table-cell;
				height: 30px;
				width: 50px;
				padding-top: 6px;
				padding-bottom: 3px;
				text-align: center;
				@extend %trans-all;
				i {
					@extend %trans-all;
				}
				&:hover {
					background-color: darken(map-get($colors, slideshow-bg), 25);
					i {
						color: #fff;
					}
					color: #fff;
					cursor: pointer;
				}
				&#slide-prev {

				}
			}
			clear:both;
		}
		&.slideshow-comments {
			.slideshow-container {
				width: 100%;
				@include mappy-bp('xlarge') {
					width: 66%;
				}
				float:left;
			}
		}
		.comments {
			float:left;
			width: 100%;
			@include mappy-bp('xlarge') {
				width: 33%;
			}
			h1, h2, h3, h4, h5, h6, p {
				@each $item, $break in $breakpoints-margins {
					@include mappy-bp($break) {
						margin-left: $break;
					}
				}
				@include mappy-bp(max-width xlarge) {
					margin-top: 20px;
					margin-left: 0px !important;
				}
			}
			h1, h2, h3, h4, h5, h6 {
				text-transform: uppercase;
				padding-bottom: 20px;
			}
		}
	}
}